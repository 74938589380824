import { faRotateRight } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import { useEffect, useState } from "react"
import Copy from "../../components/ui/Copy"
import RadioGroup from "../../components/ui/RadioGroup"
import CryptoJS from "crypto-js"

const hashes = [
    {key: "md5", title: "MD5", function: CryptoJS.MD5},
    {key: "sha1", title: "Sha1", function: CryptoJS.SHA1},
    {key: "sha3", title: "Sha3", function: CryptoJS.SHA3},
    {key: "sha256", title: "Sha256", function: CryptoJS.SHA256},
    {key: "sha512", title: "Sha512", function: CryptoJS.SHA512},
]

const AddHash = () => {

    const [text, setText] = useState()
    const [hash, setHash] = useState("")
    const [type, setType] = useState("sha256")

    useEffect(() => {
        setHash(hashes.filter(i => i.key === type)[0]['function'](text).toString())
    }, [text, type])

    const randomString = size => {
        const chars = "QAZWSXEDCRFVTGBYHNUJMIKOLPqazwsxedcrfvtgbyhnujmikolp1234567890!@#$%^&*()-=_+`~[{]}\\|;:'\",<.>/?"
        let result = ""
        while (result.length < size) {
            result += chars.charAt(Math.floor(Math.random() * size))
        }
        return result
    }

    const handlerRandom = () => {
        setText(randomString(128))
    }

    return (
        <div className={cn("flex flex-1 flex-col items-center justify-center")}>
            <div className={cn("flex flex-1 px-6 w-full flex-col justify-center items-center")}>
                <div className={"relative w-full"}>
                <input
                    placeholder={"Вставьте строку, чтобы посчитать хеш-сумму..."}
                    className={cn("w-full p-1 px-2 text-xl rounded text-black")}
                    value={text}
                    onChange={e => setText(e.target.value)}
                />
                    <button className={"absolute right-2 top-1.5 text-black"} onClick={handlerRandom}>
                        <FontAwesomeIcon icon={faRotateRight} size={"lg"} />
                    </button>
                </div>
                <Copy value={text ? hash : ""} className={"mt-4 font-mono break-all min-h-[50px]"} />
            </div>
            <div className={"flex justify-center mb-6"}>
                <RadioGroup
                    items={hashes}
                    value={type}
                    onClick={item => setType(item.key)}
                />
            </div>
        </div>
    )
}

export default AddHash