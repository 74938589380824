import axios from "axios"
import dayjs from "dayjs"
import { makeAutoObservable, configure, flow } from "mobx"
import { createContext, useContext, useMemo } from "react"

configure({enforceActions: "never"})

class Store {

    type = "draftcopy"

    draft = {
        id: false,
        status: 'not-loaded',
        name: '',
        size: 0,
        access: false,
        burn: false
    }

    darkMode = true

    version = {
        api: false,
        front: window.REACT_APP_VERSION
    }

    constructor() {
        this.load()
        makeAutoObservable(this, {
            fetchDraft: flow
        })
    }

    load () {
        this.darkMode = JSON.parse(localStorage.getItem('darkMode') ?? true)
        axios.get(window.REACT_APP_API_URL).then(result => {
            if (result.status === 200 && result.data.success) {
                this.version.api = result.data.data.version + "." + result.data.data.hash
            }
        })
    }

    setType (type) {
        this.type = type
    }

    switchDarkMode (mode) {
        this.darkMode = mode
        localStorage.setItem('darkMode', JSON.stringify(mode))
    }

    *fetchDraft () {
        if (!this.draft.id) return

        this.draft.status = "loading"
        const result = yield axios.get(window.REACT_APP_API_URL + "/" + this.draft.id)
        if (result.status === 200 && result.data.success) {
            this.draft = result.data.data
            this.draft.status = "loaded"
        } else {
            this.draft.status = "not-found"
        }
    }

    get types () {
        return [
            {
                key: "draftcopy",
                title: "Заметки"
            },
            {
                key: "json",
                title: "JSON"
            },
            {
                key: "base64",
                title: "Base64"
            },
            {
                key: "hash",
                title: "Hash"
            },
            {
                key: "uuid",
                title: "UUID"
            }
        ]
    }

    get burnLabel () {
        if (this.draft.burn === 'after-read') return "Сжигается после прочтения"
        if (this.draft.burn === '10-min') return dayjs(this.draft.created_at).to(dayjs(this.draft.created_at).add(10, 'minute'))
        if (this.draft.burn === '1-hour') return dayjs(this.draft.created_at).to(dayjs(this.draft.created_at).add(1, 'hour'))
        if (this.draft.burn === '24-hour') return dayjs(this.draft.created_at).to(dayjs(this.draft.created_at).add(24, 'hour'))
        if (this.draft.burn === '1-week') return dayjs(this.draft.created_at).to(dayjs(this.draft.created_at).add(1, 'week'))
        if (this.draft.burn === '1-month') return dayjs(this.draft.created_at).to(dayjs(this.draft.created_at).add(1, 'month'))
        if (this.draft.burn === '1-year') return dayjs(this.draft.created_at).to(dayjs(this.draft.created_at).add(1, 'year'))
        return ""
    }

    get burnRaw () {
        if (this.draft.burn === 'after-read') return "Уже удалено"
        if (this.draft.burn === '10-min') return dayjs(this.draft.created_at).add(10, 'minute').format('DD.MM.YYYY HH:mm:ss')
        if (this.draft.burn === '1-hour') return dayjs(this.draft.created_at).add(1, 'hour').format('DD.MM.YYYY HH:mm:ss')
        if (this.draft.burn === '24-hour') return dayjs(this.draft.created_at).add(24, 'hour').format('DD.MM.YYYY HH:mm:ss')
        if (this.draft.burn === '1-week') return dayjs(this.draft.created_at).add(1, 'week').format('DD.MM.YYYY HH:mm:ss')
        if (this.draft.burn === '1-month') return dayjs(this.draft.created_at).add(1, 'month').format('DD.MM.YYYY HH:mm:ss')
        if (this.draft.burn === '1-year') return dayjs(this.draft.created_at).add(1, 'year').format('DD.MM.YYYY HH:mm:ss')
        return ""
    }

    get accessLabel () {
        if (this.draft.access === 'public') return "Публичный"
        if (this.draft.access === 'private') return "Приватный"
        if (this.draft.access === 'crypt') return "Криптозащита"
        return ""
    }

    get accessRaw () {
        if (this.draft.access === 'public') return "Черновик доступен всем, в том числе поисковым ботам"
        if (this.draft.access === 'private') return "Черновик доступен только по ссылке"
        if (this.draft.access === 'crypt') return "Получить доступ можно только зная пароль"
        return ""
    }

    get sizeLabel () {
        if(!store.draft.size) return '0 Б';
        const k = 1024
        const dm = 2
        const sizes = ['Б', 'КБ', 'МБ', 'ГБ']
        const i = Math.floor(Math.log(store.draft.size) / Math.log(k))

        return parseFloat((store.draft.size / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
    }
}

const store = new Store()
const StoreContext = createContext(null)

export const StoreProvider = ({children}) => (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
)

export const useStore = () => {
    const store = useContext(StoreContext)
    if (!store) throw new Error("useStore must be used within a StoreProvider")
    return store
}