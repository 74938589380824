import { faClipboard, faDownload } from "@fortawesome/pro-duotone-svg-icons"
import {
    faAlarmClock,
    faCalendarDays,
    faFileLines,
    faLock,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import "dayjs/locale/ru"
import { useStore } from "../Store"
import { observer } from "mobx-react-lite"
import { useCopyToClipboard } from 'usehooks-ts'

dayjs.extend(relativeTime)
dayjs.locale('ru')

const liClass = "mr-4"
const iconClass = "opacity-60 mr-2"
const actionClass = "cursor-pointer ml-2 border rounded w-7 h-7 flex items-center justify-center border-draftcopy-3-light/70 dark:border-draftcopy-3-dark hover:border-black/70 dark:hover:border-white/70 px-1 hover:bg-draftcopy-1-light hover:dark:bg-draftcopy-1-dark"

const FileHeader = () => {

    const store = useStore()
    const [value, copy] = useCopyToClipboard()
    return (
        <ul className={cn(
            "bg-draftcopy-2-light px-6 py-2 flex items-center text-black/80",
            "dark:bg-draftcopy-2-dark dark:text-white"
        )}>
            <li className={liClass}><FontAwesomeIcon icon={faFileLines} className={iconClass} /><span title={store.draft.size + " B"}>{store.sizeLabel}</span></li>
            <li className={liClass}><FontAwesomeIcon icon={faCalendarDays} className={iconClass} /><span title={dayjs(store.draft.created_at).format('DD.MM.YYYY HH:mm:ss')}>{dayjs(store.draft.created_at).fromNow()}</span></li>
            <li className={liClass}><FontAwesomeIcon icon={faAlarmClock} className={iconClass} /><span title={store.burnRaw}>{store.burnLabel}</span></li>
            <li className={liClass}><FontAwesomeIcon icon={faLock} className={iconClass} /><span title={store.accessRaw}>{store.accessLabel}</span></li>
            <li className={"flex-1"}/>
            <li className={actionClass}>
                <a href={window.REACT_APP_API_URL + "/" + store.draft.id + "/download" + (store.draft.access === "crypt" ? "?auth" : "")}><FontAwesomeIcon icon={faDownload} /></a>
            </li>
            <li className={actionClass} onClick={() => copy(store.draft.decrypted ?? store.draft.content)}>
                <FontAwesomeIcon icon={faClipboard} />
            </li>
            <li className={cn([actionClass, "w-12 text-xs"])}>
                <a href={window.REACT_APP_API_URL + "/" + store.draft.id + "/raw" + (store.draft.access === "crypt" ? "?auth" : "")} target={"_blank"}>RAW</a>
            </li>
        </ul>
    )
}

export default observer(FileHeader)