import axios from "axios"
import AES from "crypto-js/aes"
import Utf8 from "crypto-js/enc-utf8"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import CryptoJSAesJson from "../crypto"
import { useStore } from "../Store"
import FilePassword from "./FilePassword"
import Loading from "./Loading"
import sleep from "./sleep"

const FileContent = () => {

    const store = useStore()

    const [content, setContent] = useState("")
    const [loading, setLoading] = useState(false)
    const [passwordLoading, setPasswordLoading] = useState(false)
    const [hidePassword, setHidePassword] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        loadContent()
    }, [store.draft.id])

    useEffect(() => {
        if (!content) return;
        store.draft.content = content
    }, [content])

    const loadContent = async () => {
        if (store.draft.status !== "loaded") return;

        setLoading(true)
        const result = await axios.get(window.REACT_APP_API_URL + "/" + store.draft.id + "/raw")
        setLoading(false)

        if (result.status === 200 && result.data.success) {
            setContent(result.data.data)
            return
        }

        setError(true)
    }

    const tryPassword = async (password) => {
        setPasswordError(false)
        setPasswordLoading(true)
        await sleep(500)

        let data = false
        try {
            data = AES.decrypt(store.draft.content, password, { format: CryptoJSAesJson }).toString(Utf8)
        } catch (e) {}

        setPasswordLoading(false)
        setPasswordError(true)

        if (data) {
            store.draft.decrypted = data
            setContent(data)
            setHidePassword(true)
        }
    }

    if (loading) return <Loading />

    return (
        <>
            { !hidePassword && store.draft.access === 'crypt' && <FilePassword error={passwordError} loading={passwordLoading} tryPassword={tryPassword} />}
            <textarea className={"flex-1 p-5 bg-transparent outline-none text-black dark:text-white"} readOnly={true} value={content} />
        </>
    )
}

export default observer(FileContent)