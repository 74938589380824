import { faMoonCloud, faSun } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { observer } from "mobx-react-lite"
import { useStore } from "../../Store"
import Switch from "../ui/Switch"

const HeaderChangeTheme = () => {

    const store = useStore()

    return (
        <div className={"flex"}>
            <div className={"flex items-center"}>
                <FontAwesomeIcon icon={faMoonCloud} size={"lg"} className={"text-black/75 dark:text-white/75"} />
                <Switch checked={!store.darkMode} onChange={(mode) => store.switchDarkMode(!mode)} className={"mx-2"} />
                <FontAwesomeIcon icon={faSun} size={"lg"} className={"text-black/75 dark:text-white/75"} />
            </div>
        </div>
    )
}

export default observer(HeaderChangeTheme)