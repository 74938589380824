import Base64 from "crypto-js/enc-base64"
import Hex from "crypto-js/enc-hex"
import CryptoJS from "crypto-js"
import Utf8 from "crypto-js/enc-utf8"

const CryptoJSAesJson = {
    stringify: function (cipherParams) {
        const j = {ct: cipherParams.ciphertext.toString(Base64)}
        if (cipherParams.iv) j.iv = cipherParams.iv.toString()
        if (cipherParams.salt) j.s = cipherParams.salt.toString()
        return Base64.stringify(Utf8.parse(JSON.stringify(j).replace(/\s/g, '')))
    },
    parse: function (jsonStr) {

        const j = JSON.parse(Base64.parse(jsonStr).toString(Utf8))
        const cipherParams = CryptoJS.lib.CipherParams.create({ciphertext: Base64.parse(j.ct)})
        if (j.iv) cipherParams.iv = Hex.parse(j.iv)
        if (j.s) cipherParams.salt = Hex.parse(j.s)
        return cipherParams;
    }
}

export default CryptoJSAesJson