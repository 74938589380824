import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"

const h3Class = "text-3xl ml-3 text-draftcopy-3-light dark:text-draftcopy-3-dark"

const HeaderNotFound = () => {

    const navigate = useNavigate()

    return (
        <>
            <button
                onClick={() => navigate(-2)}
                className={"text-4xl underline decoration-1 underline-offset-4 decoration-black/40 dark:decoration-white/40 hover:no-underline"}
            >
                <span>&larr;</span>
            </button>
            <h3 className={h3Class}>Страница не найдена</h3>
        </>
    )
}

export default observer(HeaderNotFound)