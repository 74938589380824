import { faCopyright } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import cn from "classnames"
import AES from "crypto-js/aes"
import Base64 from "crypto-js/enc-base64"
import Utf8 from "crypto-js/enc-utf8"
import { observer } from "mobx-react-lite"
import { useEffect, useMemo, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import Button from "../../components/ui/Button"
import sleep from "../../components/sleep"
import CryptoJSAesJson from "../../crypto"
import { useStore } from "../../Store"

const AddDraftcopy = () => {

    const store = useStore()

    const passwordRef = useRef(null)
    const [draft, setDraft] = useState("")
    const [title, setTitle] = useState("")
    const [access, setAccess] = useState("private")
    const [burn, setBurn] = useState("1-week")
    const [password, setPassword] = useState("")
    const [submitLoading, setSubmitLoading] = useState(false)
    const [submitDisabled, setSubmitDisabled] = useState(false)
    const [encrypt, setEncrypt] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        if (access === "crypt") {
            passwordRef.current.focus()
        }
    }, [access])

    const submit = async () => {
        setSubmitLoading(true)
        let content

        if (access === "crypt" && password.length) {
            setEncrypt(true)
            content = AES.encrypt(draft, password, {format: CryptoJSAesJson}).toString()
            setDraft(content)
            await sleep(1000)
            setEncrypt(false)
        } else {
            content = Base64.stringify(Utf8.parse(draft))
        }

        const result = await axios.post(window.REACT_APP_API_URL + "/create", {
            title,
            content,
            access,
            burn,
        })

        setSubmitLoading(false)

        if (result.status !== 200 || !result.data.success) {
            alert(result.data.error ?? "Неизвестная ошибка, попробуйте позже")
            return
        }

        store.draft = result.data.data
        store.draft.status = "loaded"
        navigate("/_/" + result.data.data.id)
    }

    const isSubmitDisabled = useMemo(() => {
        return submitDisabled || submitLoading || (access === "crypt" && password.length === 0) || draft.length === 0
    }, [draft, access, password, submitDisabled, submitLoading])

    const submitLabel = useMemo(() => {
        if (encrypt) return "Шифрование"
        if (submitLoading) return "Загрузка"
        return "Продолжить"
    }, [submitLoading, encrypt])

    return (
        <div className={"main flex flex-1"}>
                <textarea
                    className={"flex-1 mx-6 mb-6 bg-transparent outline-none text-black dark:text-white"} autoFocus
                    placeholder={"Вставьте текст сюда..."}
                    disabled={submitLoading}
                    value={draft}
                    onChange={event => setDraft(event.target.value)}
                />
            <div className={cn(
                "flex flex-col absolute bottom-6 right-6 bg-draftcopy-2-light p-4 shadow-xl text-black/90",
                "dark:bg-draftcopy-2-dark dark:text-white"
            )}>
                <div className={"flex mb-2 items-center"}>
                    <label className={"w-[160px]"}>Наименование</label>
                    <input
                        placeholder={"Наименование..."}
                        value={title}
                        name={"new-password"}
                        onChange={event => setTitle(event.target.value)}
                        className={"w-[220px] bg-draftcopy-1 p-1 bg-draftcopy-1-light dark:bg-draftcopy-1-dark"}
                    />
                </div>
                <div className={"flex mb-2 items-center"}>
                    <label className={"w-[160px]"}>Сжечь черновик</label>
                    <select
                        className={"w-[220px] bg-draftcopy-1 p-1 bg-draftcopy-1-light dark:bg-draftcopy-1-dark"}
                        onChange={event => setBurn(event.target.value)}
                        defaultValue={burn}
                    >
                        <option value="after-read">После прочтения</option>
                        <option value="10-min">Через 10 минут</option>
                        <option value="1-hour">Через час</option>
                        <option value="24-hour">Через день</option>
                        <option value="1-week">Через неделю</option>
                        <option value="1-month">Через месяц</option>
                        <option value="1-year">Через год</option>
                    </select>
                </div>
                <div className={"flex mb-2 items-center"}>
                    <label className={"w-[160px]"}>Публичность</label>
                    <select
                        className={"w-[220px] bg-draftcopy-1 p-1 bg-draftcopy-1-light dark:bg-draftcopy-1-dark"}
                        onChange={event => setAccess(event.target.value)}
                        defaultValue={access}
                    >
                        {/*<option value="public">Доступно всем</option>*/}
                        <option value="private">Только по ссылке</option>
                        <option value="crypt">Только по паролю</option>
                    </select>
                </div>
                <div className={cn("flex mb-2 items-center", { hidden: access !== "crypt" })}>
                    <label className={"w-[160px]"}>Пароль</label>
                    <input
                        type={"password"}
                        value={password}
                        ref={passwordRef}
                        onChange={event => setPassword(event.target.value)}
                        autoComplete={"new-password"}
                        className={"w-[220px] bg-draftcopy-1 p-1 bg-draftcopy-1-light dark:bg-draftcopy-1-dark"}
                    />
                </div>
                <div className={"mt-4 self-end w-[220px]"}>
                    <Button className={"w-full"} onClick={submit} disabled={isSubmitDisabled} loading={submitLoading}>{submitLabel}</Button>
                </div>
            </div>
            <div className={cn(
                "flex flex-row items-center absolute bottom-6 left-6 text-black/50 text-sm",
                "dark:text-white/50"
            )}>
                <FontAwesomeIcon icon={faCopyright} />
                <span className={"ml-1"}>2024</span>
                <span className={"ml-2"}>web: {store.version.front}</span>
                {store.version.api !== false && <span className={"ml-3"}>api: {store.version.api}</span>}
            </div>
        </div>
    )
}

export default observer(AddDraftcopy)