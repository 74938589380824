import cn from "classnames"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"
import { useStore } from "../../Store"

const h2Class = "text-3xl ml-3 text-black/90 dark:text-white"
const h3Class = "text-3xl ml-3 text-draftcopy-3-light dark:text-draftcopy-3-dark"

const HeaderInfo = () => {

    const store = useStore()
    const navigate = useNavigate()

    return (
        <div className={"flex"}>
            <button
                onClick={() => navigate(-1)}
                className={cn(
                "text-4xl underline decoration-1 underline-offset-4 decoration-black/40 hover:no-underline text-black/90",
                "dark:text-white dark:decoration-white/40"
            )}>
                <span>&larr;</span>
            </button>
            <>
                <h2 className={h2Class}>{store.draft.title ?? "Без названия"}</h2>
                <h3 className={h3Class}>{store.draft.id}</h3>
            </>
        </div>
    )
}

export default observer(HeaderInfo)