import cn from "classnames"
import { observer } from "mobx-react-lite"
import { Link } from "react-router-dom"
import { useStore } from "../../Store"
import RadioGroup from "../ui/RadioGroup"

const HeaderAdd = () => {
    const store = useStore()
    return (
        <div className={"flex items-center"}>
            <Link className={cn(
                "text-4xl underline decoration-1 underline-offset-4 decoration-black/40 hover:no-underline text-black/80",
                "dark:decoration-white/40 dark:text-white",
            )} to={"/"}>
                <span>Draftcopy.ru</span>
            </Link>
            <RadioGroup
                items={[...store.types]}
                value={store.type}
                to={item => "/" + (item.key === "draftcopy" ? "" : item.key)}
            />
        </div>
    )
}

export default observer(HeaderAdd)