import { useMemo } from "react"
import { useMatches } from "react-router-dom"
import { observer } from "mobx-react-lite"
import HeaderAdd from "./Header/HeaderAdd"
import HeaderChangeTheme from "./Header/HeaderChangeTheme"
import HeaderInfo from "./Header/HeaderInfo"
import HeaderNotFound from "./Header/HeaderNotFound"

const Header = () => {
    const matches = useMatches()
    const page = useMemo(() => matches[matches.length - 1], [matches])

    return (
        <header className={"header py-5 px-6 flex items-center justify-center"}>
            <div className={"flex-1"}>
                {page.id === "add" && <HeaderAdd />}
                {page.id === "info" && <HeaderInfo />}
                {page.id === "404" && <HeaderNotFound />}
            </div>
            <HeaderChangeTheme />
        </header>
    )
}

export default observer(Header)