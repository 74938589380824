import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import Wrapper from "./components/Wrapper"
import Add from "./page/Add"
import Info from "./page/Info"
import Loading from "./components/Loading"
import NotFound from "./page/NotFound"
import { StoreProvider } from "./Store"

const router = createBrowserRouter([
    {
        element: <Wrapper />,
        loader: Loading,
        children: [
            { index: true, path: "/:type?", element: <Add />, id: 'add' },
            { path: "/_/:draft", element: <Info />, id: 'info' },
            { path: "/404", element: <NotFound />, id: '404' },
        ]
    },
]);

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
    <React.StrictMode>
        <StoreProvider>
            <RouterProvider router={router} />
        </StoreProvider>
    </React.StrictMode>,
)
