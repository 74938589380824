import { observer } from "mobx-react-lite"
import { Outlet } from "react-router-dom"
import { useStore } from "../Store"
import Header from "./Header"

const Wrapper = () => {

    const store = useStore()

    return (
        <div className={store.darkMode && "dark"}>
            <main className="flex min-h-screen flex-col bg-draftcopy-1-light dark:bg-draftcopy-1-dark">
                <Header/>
                <Outlet/>
            </main>
        </div>
    )
}

export default observer(Wrapper)