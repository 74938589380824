import {
    ReflexContainer,
    ReflexSplitter,
    ReflexElement,
} from "react-reflex"

import { faEllipsisVertical, faFunction, faText } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import { observer } from "mobx-react-lite"
import { useMemo, useState } from "react"
import RadioGroup from "../../components/ui/RadioGroup"
import { useStore } from "../../Store"
import JsonView from "react18-json-view"
import "react18-json-view/src/style.css"
import "react18-json-view/src/dark.css"
import "react-reflex/styles.css"

const liClass = "p-0.5 px-2 block"
const actions = [
    { key: "object", element: <FontAwesomeIcon icon={faFunction} size={"sm"}/> },
    { key: "text", element: <FontAwesomeIcon icon={faText} size={"sm"}/> },
]

const AddJson = () => {

    const [json, setJson] = useState("")
    const [viewType, setViewType] = useState("object")
    const [error, setError] = useState(false)
    const store = useStore()

    const parsedJson = useMemo(() => {
        if (json === "") return ""
        let parsed = null
        try {
            parsed = JSON.parse(json)
        } catch (error) {
            setError(true)
            return error.message
        }
        setError(false)
        return parsed
    }, [json])

    return (
        <div className={"flex flex-1"}>
            <ReflexContainer orientation="vertical" className={"flex flex-1 !h-auto"}>
                <ReflexElement className={"flex"} minSize={200}>
                    <textarea
                        className={cn(
                            "flex-1 px-6 pb-6 bg-transparent outline-none pr-2 text-black",
                            "dark:text-white",
                        )}
                        value={json}
                        placeholder={"Вставьте json сюда..."}
                        onChange={event => setJson(event.target.value)}
                    />
                </ReflexElement>
                <ReflexSplitter className={cn(
                    "flex p-1 items-center justify-center !border-transparent  !bg-black/10 text-black",
                    "!dark:bg-white/10 dark:text-white",
                )}>
                    <FontAwesomeIcon icon={faEllipsisVertical}/>
                </ReflexSplitter>
                <ReflexElement minSize={200}>
                    <div className={"flex-1 px-6 pb-6 pl-2"}>
                        <div>
                            {
                                error
                                    ? (
                                        <div className={"bg-red-800 p-2 px-4"}>
                                            <p>Ошибка парсинга JSON:</p>
                                            <p>{parsedJson}</p>
                                        </div>
                                    )
                                    : (
                                        <div className={"relative"}>
                                            <div className={"text-sm"}>
                                                {
                                                    viewType === "object"
                                                        ? (
                                                            <JsonView
                                                                displaySize={true}
                                                                theme={"atom"}
                                                                dark={store.darkMode}
                                                                src={parsedJson}
                                                            />
                                                        )
                                                        : (
                                                            <pre className={cn(
                                                                "whitespace-pre-wrap text-black",
                                                                "dark:text-white"
                                                            )}>
                                                                {JSON.stringify(parsedJson, null, 2)}
                                                            </pre>
                                                        )
                                                }
                                            </div>
                                            <div className={"flex items-center absolute top-0 right-0"}>
                                                <RadioGroup
                                                    items={actions}
                                                    value={viewType}
                                                    onClick={item => setViewType(item.key)}
                                                />
                                            </div>
                                        </div>
                                    )
                            }

                        </div>
                    </div>
                </ReflexElement>
            </ReflexContainer>
        </div>
    )
}
export default observer(AddJson)