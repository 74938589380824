import cn from "classnames"
import { useEffect, useState } from "react"

const Switch = ({ className = "", checked = false, onChange = () => {} }) => {

    const [isChecked, setIsChecked] = useState(checked)

    useEffect(() => {
        setIsChecked(checked)
    }, [checked])

    const handledChange = () => {
        setIsChecked(!isChecked)
        onChange(!isChecked)
        console.log('checked', !isChecked)
    }

    return (
        <label className={cn("relative inline-flex items-center cursor-pointer", className)}>
            <input type={"checkbox"} checked={isChecked} onChange={handledChange} className={"sr-only peer"}/>
            <div className={cn(
                "outline-none w-11 h-6 bg-gray-200 rounded-full peer after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all",
                "peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white peer-checked:bg-blue-500",
                "dark:bg-black/40  dark:peer-focus:ring-blue-800 dark:border-gray-600"
            )}></div>
        </label>
    )
}

export default Switch
