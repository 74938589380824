import { faRotateRight } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import { useEffect, useState } from "react"
import Button from "../../components/ui/Button"
import Copy from "../../components/ui/Copy"
import RadioGroup from "../../components/ui/RadioGroup"
import { ulid } from 'ulid'
import { v4 } from 'uuid'
import { nanoid } from 'nanoid'

const uuids = [
    {key: "uuid", title: "UUID", function: v4},
    {key: "ulid", title: "ULID", function: ulid},
    {key: "nanoid", title: "NanoID", function: nanoid},
]

const AddUuid = () => {

    const [uuid, setUuid] = useState("")
    const [type, setType] = useState("uuid")

    useEffect(() => {
        handlerRandom()
    }, [type])

    const handlerRandom = () => {
        setUuid(uuids.filter(i => i.key === type)[0]['function']())
    }

    return (
        <div className={cn("flex flex-1 flex-col items-center justify-center")}>
            <div className={cn("flex flex-1 px-6 w-full flex-row justify-center items-center")}>
                <Copy value={uuid} className={"font-mono break-all"}/>
                <Button className={"!p-1 !px-1.5 !pb-0.5 ml-2"} onClick={handlerRandom}>
                    <FontAwesomeIcon icon={faRotateRight} size={"lg"}/>
                </Button>
            </div>
            <div className={"flex justify-center mb-6"}>
                <RadioGroup
                    items={uuids}
                    value={type}
                    onClick={item => setType(item.key)}
                />
            </div>
        </div>
    )
}

export default AddUuid