import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import { useEffect, useState } from "react"

const Button = ({
    disabled,
    loading,
    onClick,
    children,
    className
}) => {

    const [buttonPress, setButtonPress] = useState(false)

    useEffect(() => {
        if (buttonPress) {
            document.addEventListener('mouseup', handlerMouseUp);
            return () => {
                document.removeEventListener('mouseup', handlerMouseUp);
            };
        }
    }, [buttonPress])

    const handlerMouseUp = () => {
        setButtonPress(false)
    }

    return (
        <button
            onMouseDown={() => setButtonPress(true)}
            className={cn(
                "text-white bg-blue-500 hover:bg-blue-600 text-l py-2 px-4 rounded border-b-[3px] border-blue-800",
                className,
                {"bg-gray-400 hover:bg-gray-400 dark:bg-gray-500 dark:hover:bg-gray-500 border-gray-700 text-white/50": disabled},
                {"mt-[2px] border-b-[1px]": buttonPress}
            )}
            type="submit"
            disabled={disabled}
            onClick={onClick}
        >
            {loading && <FontAwesomeIcon className={"mr-2"} icon={faSpinnerThird} spin={true} />}
            <span>{children}</span>
        </button>
    )
}

export default Button