import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Loading = () => {
    return (
        <div className={"flex flex-1 items-center justify-center text-6xl text-black/80 dark:text-white/80"}>
            <FontAwesomeIcon icon={faSpinnerThird} spin />
        </div>
    )
}

export default Loading