import {
    ReflexContainer,
    ReflexSplitter,
    ReflexElement,
} from "react-reflex"

import { faEllipsis } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import Base64 from "crypto-js/enc-base64"
import Utf8 from "crypto-js/enc-utf8"
import "react18-json-view/src/style.css"
import "react18-json-view/src/dark.css"
import "react-reflex/styles.css"

const AddJson = () => {

    const [text, setText] = useState("")
    const [base64, setBase64] = useState("")
    const [focus, setFocsus] = useState(false)

    useEffect(() => {
        if (focus === "text") setBase64(Base64.stringify(Utf8.parse(text)))
    }, [text])

    useEffect(() => {
        if (focus === "base64") setText(Utf8.stringify(Base64.parse(base64)))
    }, [base64])

    return (
        <div className={"flex flex-1"}>
            <ReflexContainer orientation="horizontal" className={"flex flex-1 !h-auto"}>
                <ReflexElement className={"flex"} minSize={60}>
                    <textarea
                        onFocus={() => setFocsus("text")}
                        onBlur={() => setFocsus(false)}
                        className={cn(
                            "flex-1 px-6 pb-6 bg-transparent outline-none pr-2 text-black",
                            "dark:text-white",
                        )}
                        value={text}
                        placeholder={"Текст сюда..."}
                        onChange={event => setText(event.target.value)}
                    />
                </ReflexElement>
                <ReflexSplitter className={cn(
                    "flex p-1 items-center justify-center !border-transparent !bg-black/10 text-black",
                    "!dark:bg-white/10 dark:text-white",
                )}>
                    <FontAwesomeIcon icon={faEllipsis}/>
                </ReflexSplitter>
                <ReflexElement className={"flex"} minSize={60}>
                    <textarea
                        onFocus={() => setFocsus("base64")}
                        onBlur={() => setFocsus(false)}
                        className={cn(
                            "flex-1 px-6 pb-6 bg-transparent outline-none pr-2 pt-2 text-black",
                            "dark:text-white",
                        )}
                        value={base64}
                        placeholder={"Base64 сюда..."}
                        onChange={event => setBase64(event.target.value)}
                    />
                </ReflexElement>
            </ReflexContainer>
        </div>
    )
}
export default observer(AddJson)