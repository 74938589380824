import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons"
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import { useState } from "react"
import Button from "./ui/Button"

const FilePassword = ({
    tryPassword,
    loading = true,
    error
}) => {

    const [password, setPassword] = useState("")

    return (
        <div className={cn("flex px-5 mt-5 items-center justify-center", {"animate-shake": error})}>
            <span className={"text-xl text-black dark:text-white"}>Пароль</span>
            <input value={password} onChange={event => setPassword(event.target.value)} disabled={loading} autoFocus={true} className={"w-[220px] p-2 bg-draftcopy-2-light dark:bg-draftcopy-2-dark mx-3 rounded text-black dark:text-white"}/>
            <Button onClick={() => tryPassword(password)} disabled={loading || !password.length}><FontAwesomeIcon icon={loading ? faSpinnerThird : faArrowRight} spin={loading} /></Button>
        </div>
    )
}

export default FilePassword