import { useEffect } from "react"
import { useParams } from "react-router-dom"
import FileContent from "../components/FileContent"
import FileHeader from "../components/FileHeader"
import Loading from "../components/Loading"
import { useStore } from "../Store"
import { observer } from "mobx-react-lite"
import NotFound from "./NotFound"

const Info = () => {
    const { draft } = useParams()
    const store = useStore()

    useEffect(() => {
        if (store.draft.id === draft && store.draft.status === "loaded") return
        store.draft.id = draft
        store.fetchDraft()
    }, [draft])

    if (store.draft.status === "loading") return <Loading />

    return store.draft.status === 'not-found'
        ? <NotFound />
        : (
        <>
            <FileHeader />
            <FileContent />
        </>
    )
}

export default observer(Info)