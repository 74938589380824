import cn from "classnames"
import { Link, useNavigate } from "react-router-dom"

const RadioGroup = ({
    items,
    value,
    to = false,
    onClick = false,
}) => {

    const navigate = useNavigate()

    const handlerClick = item => {
        let url = false
        if (typeof to === "function") {
            url = to(item)
        } else if (typeof to === "string") {
            url = to
        } else if (onClick) {
            return onClick(item)
        }
        if (url) navigate(url)
    }

    return (
        <ul className={cn(
            "ml-6 flex border rounded-md border-blue-500 overflow-hidden p-[1px]",
            "dark:border-white/20",
        )}>
            {
                items.map((item, index) => {
                    const active = value === item.key
                    const className = cn(
                        "p-1 px-2.5 block hover:underline underline-offset-2 decoration-blue-300",
                        "dark:decoration-white/20",
                        active ? [
                            "bg-blue-500 dark:bg-white/20 dark:text-white hover:no-underline",
                        ] : [
                            "text-blue-500 dark:text-white/70",
                        ],
                        index === 0 && "rounded-l",
                        index === items.length - 1 && "rounded-r",
                    )

                    return (
                        <li key={"li-" + item.key}>
                            <button onClick={() => handlerClick(item)} className={className}>
                                {
                                    item.title
                                        ? item.title
                                        : item.element
                                            ? item.element
                                            : null
                                }
                            </button>
                        </li>
                    )
                })
            }
        </ul>
    )
}

export default RadioGroup