import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { useStore } from "../Store"
import AddBase64 from "./Add/AddBase64"
import AddDraftcopy from "./Add/AddDraftcopy"
import AddHash from "./Add/AddHash"
import AddJson from "./Add/AddJson"
import AddUuid from "./Add/AddUuid"

const Add = () => {

    const { type } = useParams()
    const store = useStore()
    const navigate = useNavigate()

    useEffect(() => {
        if (type && !store.types.filter(i => i.key === type).length) navigate('/404')
        if (type !== store.type) store.setType(type ?? "draftcopy")
    }, [type])

    if (type === 'draftcopy' || !type) return <AddDraftcopy />
    if (type === 'json') return <AddJson />
    if (type === 'base64') return <AddBase64 />
    if (type === 'hash') return <AddHash />
    if (type === 'uuid') return <AddUuid />
}

export default observer(Add)