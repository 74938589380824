import { faClipboard, faClipboardCheck } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import { useState } from "react"
import { useCopyToClipboard } from "usehooks-ts"
import Button from "../ui/Button"

const Copy = ({ value, className }) => {

    const [_, copy] = useCopyToClipboard()
    const [copied, setCopied] = useState(false)

    const handlerCopy = () => {
        copy(value).then(() => {
            setCopied(true)
            setTimeout(() => setCopied(false), 2000)
        })
    }

    return (
        <div className={cn(
            "flex justify-center items-center cursor-pointer text-black",
            "dark:text-white",
            className
        )}  onClick={handlerCopy}>
            <span className={"text-lg"}>{value !== "" ? value : "\u00A0"}</span>
            {
                value !== "" && (
                    <Button className={cn(
                        "ml-4 !p-1 !px-2.5  !pb-0.5",
                        {"!bg-green-600": copied}
                    )}>
                        <FontAwesomeIcon icon={copied ? faClipboardCheck : faClipboard} />
                    </Button>
                )
            }
        </div>
    )
}

export default Copy